/* eslint-disable no-restricted-imports */
import React, { useCallback } from 'react'
import {
  TouchableWithoutFeedback as RNTouchableWithoutFeedback,
  TouchableHighlight as RNTouchableHighlight,
  TouchableNativeFeedback as RNTouchableNativeFeedback,
  TouchableOpacity as RNTouchableOpacity,
  TouchableWithoutFeedbackProps,
  GestureResponderEvent,
  TouchableOpacityProps,
  TouchableNativeFeedbackProps,
  TouchableHighlightProps,
  Platform,
  StyleSheet,
} from 'react-native'

import { useThrottleStateCallback } from '../modules/useThrottleEventCallback'

const styles = StyleSheet.create({
  root: Platform.select({
    web: {
      userSelect: 'auto',
    },
    default: {},
  }),
})

/*
  やってることの説明:

  1. onPressにデフォルトでON_PRESS_MIN_INTERVALぶんのthrottleを入れている

  理由: 短時間の連続したタップを無視した方が体験上良い場合が多いので。
  例:APIを呼び出して何かを表示する、モーダルを開く、フォローをする操作、など

  ただし、ボタンのトグルなど、即座にUIに反映できて副作用がない場合など
  一部の操作はthrottleして欲しくない場合もあるので、そういった時は
  <TouchableOpacity
    onPress={() => {
      console.log('onPressMinIntervalを0にするとthrottleされない')
    }}
    onPressMinInterval={0}
  />
  のようにonPressMinIntervalを指定する


  2. デフォルトでhitSlopを上下左右に10ずつ入れている
  ほとんどの場合ではこれで問題ないが、
  ボタンの配置間隔が狭い場合などはhitSlopを含めてかぶると片方が押せなくなる場合があるのでその場合は手動で指定して上書きすると良い

  例: paddingがない横並びのボタン
  <View style={{ flexDirection: 'row' }}>
    <TouchableOpacity
      style={{width: 32, height: 32}}
      hitSlop={{ top: 10, left: 10, right: 0, bottom: 10 }}
    />
    <TouchableOpacity
      style={{width: 32, height: 32}}
      hitSlop={{ top: 10, left: 0, right: 10, bottom: 10 }}
    />
  </View>

*/

const ON_PRESS_MIN_INTERVAL = 600

const defaultHitSlop = Object.freeze({
  top: 10,
  left: 10,
  right: 10,
  bottom: 10,
})

interface InjectedProps {
  onPressMinInterval?: number
  children?: React.ReactNode
}

function createTouchable<T, P extends TouchableWithoutFeedbackProps>(
  TouchableComponent:
    | React.ComponentType<React.PropsWithChildren<P>>
    | React.ForwardRefExoticComponent<P & React.RefAttributes<T>>
) {
  return React.forwardRef<T, P & InjectedProps>(function Touchable(
    props: React.PropsWithoutRef<P & InjectedProps>,
    ref
  ) {
    const {
      onPress,
      onPressMinInterval = ON_PRESS_MIN_INTERVAL,
      hitSlop = defaultHitSlop,
      style,
      ...restProps
    } = props

    const memorizedOnPress = useCallback(
      (event: GestureResponderEvent) => {
        onPress?.(event)
      },
      [onPress]
    )

    const { throttledCallback: throttledOnPress, enable } =
      useThrottleStateCallback(memorizedOnPress, onPressMinInterval)

    return (
      <TouchableComponent
        ref={ref}
        hitSlop={hitSlop}
        onPress={throttledOnPress}
        disabled={props?.disabled ?? !enable}
        style={[styles.root, style]}
        {...(restProps as unknown as P)}
      />
    )
  })
}

type WebProps = {
  href?: string
  hrefAttrs?: {
    target?: string
    rel?: string
  }
}

const TouchableOpacity = createTouchable<
  React.ElementRef<typeof RNTouchableOpacity>,
  TouchableOpacityProps & WebProps
>(RNTouchableOpacity)
const TouchableNativeFeedback = createTouchable<
  RNTouchableNativeFeedback,
  TouchableNativeFeedbackProps & WebProps
>(RNTouchableNativeFeedback)
const TouchableHighlight = createTouchable<
  React.ElementRef<typeof RNTouchableHighlight>,
  TouchableHighlightProps & WebProps
>(RNTouchableHighlight)
const TouchableWithoutFeedback = createTouchable<
  TouchableWithoutFeedbackProps,
  TouchableWithoutFeedbackProps & WebProps
>(RNTouchableWithoutFeedback)

export {
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableHighlight,
  TouchableWithoutFeedback,
}
