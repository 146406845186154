// eslint-disable-next-line no-restricted-imports
import dayjs, { type Dayjs } from 'dayjs'
import ja from 'dayjs/locale/ja'
import zhCn from 'dayjs/locale/zh-cn'
import zhTw from 'dayjs/locale/zh-tw'
import zhHk from 'dayjs/locale/zh-hk'
import ko from 'dayjs/locale/ko'
import en from 'dayjs/locale/en'
import th from 'dayjs/locale/th'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'

import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

type ExtendedFormats = {
  LT: string
  LTS: string
  L: string
  LL: string
  LLL: string
  LLLL: string
  lm: string
  lml: string
  lmll: string
}

/* eslint-disable ar-i18n/require-translation-ja */
ja.formats = {
  /*
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年M月D日',
    LLL: 'YYYY年M月D日 HH:mm',
    LLLL: 'YYYY年M月D日 dddd HH:mm',
    l: 'YYYY/MM/DD',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日(ddd) HH:mm'
  */
  ...ja.formats,
  LLL: 'YYYY年M月D日 HH時mm分',
  LLLL: 'YYYY年M月D日(ddd) HH時mm分',
  lm: 'M月D日',
  lml: 'M月D日 HH:mm',
  lmll: 'M月D日(ddd) HH:mm',
} as ExtendedFormats
/* eslint-enable ar-i18n/require-translation-ja */

en.formats = {
  ...en.formats,
  LTS: 'h:mm:ss A',
  LT: 'h:mm A',
  L: 'MM/DD/YYYY',
  LL: 'MMMM D, YYYY',
  LLL: 'MMMM D, YYYY h:mm A',
  LLLL: 'dddd, MMMM D, YYYY h:mm A',
  lm: 'MMMM D',
  lml: 'MMMM D h:mm A',
  lmll: 'dddd, MMMM D h:mm A',
} as ExtendedFormats

// dayjs/plugin/localizedFormat
// eslint-disable-next-line no-restricted-syntax
export const LocalizedFormat: dayjs.PluginFunc = (
  option,
  dayjsClass,
  dayjsFactory
) => {
  // @ts-ignore
  dayjsFactory.en.formats = en.formats
  const format = (format: string | undefined) => {
    return format?.replace(
      /(\[[^\]]+])|(MMMM|MM|DD|dddd)/g,
      // eslint-disable-next-line
      (_, a, b) => a || b.slice(1)
    )
  }

  const oldFormat = dayjsClass.prototype.format

  dayjsClass.prototype.format = function (formatStr: string) {
    // @ts-ignore
    const {
      formats = {},
    }: {
      formats: Partial<ExtendedFormats>
    } = (this as any).$locale()

    const result = formatStr?.replace(
      /(\[[^\]]+])|(LTS?|lml{0,2}|l{1,4}|L{1,4})/g,
      (
        _, // matchしたテキスト全体

        a?: string, // [YYYY text]のようなテキストをYYYY textのようにescapeされたまま返すために使う
        b?: string
      ) => {
        const B = b?.toUpperCase()
        /* eslint-disable */
        // @ts-ignore
        return a || formats[b] || en.formats[b] || format(formats[B])
        /* eslint-enable */
      }
    )
    return oldFormat.call(this, result)
  }
}

// タイムゾーンを上書きできるようにするためのutility
export function overrideTimeZone(timeZone: string) {
  const OriginalDateTimeFormat = Intl.DateTimeFormat

  global.Intl.DateTimeFormat = function DateTimeFormat(locales, options) {
    return OriginalDateTimeFormat(
      locales,
      options != null
        ? options
        : {
            timeZone,
          }
    )
  } as typeof Intl.DateTimeFormat

  return () => {
    Intl.DateTimeFormat = OriginalDateTimeFormat
  }
}

export function initDayjs(locale: string) {
  dayjs.extend(isBetween)

  dayjs.locale('ja', ja)
  dayjs.locale('zh', zhCn)
  dayjs.locale('zh-CN', zhCn)
  dayjs.locale('zh-TW', zhTw)
  dayjs.locale('zh-HK', zhHk)
  dayjs.locale('ko', ko)
  dayjs.locale('en', en)
  dayjs.locale('th', th)

  dayjs.extend(utc)
  dayjs.extend(timezone)

  dayjs.tz.setDefault(dayjs.tz.guess())

  dayjs.extend(localeData)
  dayjs.extend(isSameOrAfter)

  // ar_nativeでフォーマットが壊れるため、dayjs.extend(LocalizedFormat)はここで呼ばない

  // eslint-disable-next-line ar-timezone/require-timezone
  dayjs().localeData()

  dayjs.locale(locale)
}

export type { Dayjs }

export default dayjs
