import React from 'react'
import { Image as RNImage, ImageProps as RNImageProps } from 'react-native'

import {
  ExtendedImageSourceProp,
  resolveImageSource,
} from '../../modules/resolveImageSource'

export * from './types'

export type ImageProps = Omit<RNImageProps, 'source'> & {
  source: ExtendedImageSourceProp
}

export function Image(props: ImageProps) {
  const resolvedSource = resolveImageSource(props.source)

  return (
    <RNImage
      {...props}
      style={[
        // iOSで画像が表示されない問題への対応
        // https://github.com/necolas/react-native-web/issues/2760#issuecomment-2669694643
        {
          willChange: 'transform',
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
        },
        props.style,
      ]}
      // @ts-expect-error
      source={resolvedSource}
    />
  )
}
